.App {
  text-align: center;
  background-color: rgb(136, 136, 136);

  /* overflow : hidden */
}


.container-app {
  background-color: white;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.main{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-title {
  /* border : solid 1px black; */
  background-color: rgb(241, 241, 241);
  width: 100%;
}

.border {
  border : solid 1px gray;
  border-radius: 5px;
}
.border-red {
  border : solid 2px rgb(247, 12, 12) !important;
  border-radius: 5px;
  padding: 5px;
}
.container-desc {
  width: 90%;
  padding: 1%;
  /* background-color: aqua; */
}

.container-data {
  width: 50%;
  
}

.container-result {
  padding: 10px;
}

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
}

.tab {
  background-color: lightgray;
}


.display-btn {
  width: 100%;
  min-height: 20vh;
}
.disabled-btn {
  color: rgb(189, 189, 189)!important;
}
.prev-number {
  font-size: 50px;
}
.prev-type {
  margin-top: 15px;
  font-size: 20px;
}

.user-list {
  background-color: #e9ebee;
  max-height: 50vh;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}


.user-item {
  background-color: white;
  border-radius: 10px;
  border-radius: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.button {
  background-color: lightgray;
  padding: 20px;
}
.button2 {
  background-color: transparent;
border: 1px solid rgba(228, 228, 233, 0.89);
padding: 10px;
cursor: pointer;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}
input[type=date], select {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
input[type=submit]:hover {
  background-color: #45a049;
}

/* Hide the default arrow icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  /* display: none; */
  
}

/* Add a custom icon using a background image */



.commun-button {
  border: none;
  color: white;
  padding: 15px 32px;
  /* padding: 5%; */
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.btn-green {
  background-color: #04AA6D; /* Green */
}

.btn-green:hover {
  background-color: #057d4a; /* Darker Green on Hover */
}

.btn-blue {
  background-color: #008CBA; /* Blue */
}

.btn-blue:hover {
  background-color: #006791; /* Darker Blue on Hover */
}

.btn-red {
  background-color: #f44336; /* Red */
}

.btn-red:hover {
  background-color: #c62828; /* Darker Red on Hover */
}

.btn-yellow {
  background-color: #f4d836; /* Red */
}

.btn-yellow:hover {
  background-color: #bbc628; /* Darker Red on Hover */
}

.btn-gray {
  background-color: #e7e7e7;
  color: black; /* Gray */
}

.btn-gray:hover {
  background-color: #c4c4c4; /* Darker Gray on Hover */
}

.btn-black {
  background-color: #555555; /* Black */
}

.btn-black:hover {
  background-color: #333333; /* Darker Black on Hover */
}
.btn-light {
  background-color: #fcfcfc; /* Black */
  color: black;
}

.btn-light:hover {
  background-color: #f0f0f0; /* Darker Black on Hover */
}

/* App.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin: 5px;
}

.close-button {
  border: none;
  background-color: transparent;
  font-size: 40px;
  cursor: pointer;
}
.empty-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
/* Increase the size of the checkbox */
input[type="checkbox"] {
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -moz-transform: scale(1.5); /* Firefox */
  -ms-transform: scale(1.5); /* IE 9 */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
}

.logo {
  text-align: left;
}
.logo > img {
  margin-left: 30%;
  width: 10%;
}
.language-switcher {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

/* Styles pour le fond d'écran (backdrop) du modal */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur semi-transparente */
  z-index: 999; /* S'assurer qu'il est bien au-dessus des autres éléments */
}

/* Styles pour le contenu du modal */
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Plus élevé que le backdrop */
  width: 90%;
  max-width: 500px; /* Taille maximale du modal */
}

/* Optionnel: empêcher le défilement en arrière-plan lorsque le modal est ouvert */
body.modal-open {
  overflow: hidden;
}

/* Styles pour le fond d'écran (backdrop) du modal */
.modal-backdrop.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040; /* Légèrement inférieur à celui du modal */
}

.paid-icon {

  width: 0px;
}

